import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Svg from "@/Shared/Svg";
import css from "./CheckboxFilter.module.css";

import { bindActionCreators } from "redux";
import {
  changeFilters,
  reinitializeAdsFiltersAndOrder,
} from "@/Store/actions/ads";
import { connect } from "react-redux";
import { ADS_FILTERS } from "@/Constants/filters";

// TODO this component is too smart and heavily tied to one context, making it no reusable in other contexts.
//  It should not be connected to redux. This is not an input tag anymore
//  but a whole system repeated on all its occurrences. It just should have classes and give
//  back info to parent form in charge of mutating the data, not mutating them

const CheckboxFilter = ({
  onChangeCallback = async () => {},
  className = null,
  changeFilters,
  label = null,
  filter,
  name,
  options = {
    arrayFilters: [],
    isFilterToReinitialize: false,
  },
  parent,
  reinitializeAdsFiltersAndOrder,
  value,
}) => {
  options = {
    arrayFilters: options?.arrayFilters ?? [],
    isFilterToReinitialize: options?.isFilterToReinitialize ?? false,
  };

  if (!label) {
    label = value?.label ? <span>{value.label}</span> : "";
  }

  if (!className) {
    className = `f-primary ${
      filter.type ? css["c-critere-" + filter.type] : ""
    }`;
    className = `${className} ${value.checked ? css["selected"] : ""} ${
      value?.disabled ? css["display-none"] : ""
    }`;
  }

  const handleClick = async e => {
    let values = [];
    let filterFunction = ({ filter, values }) => {
      changeFilters(name, { ...filter, values: [...values] });
    };

    if (parent) {
      values = filter.values.map(v =>
        v.label === parent.label
          ? {
              ...parent,
              values: parent.values.map(child =>
                child.label === value.label
                  ? {
                      ...value,
                      checked: e.target.checked,
                      disabled: false,
                    }
                  : child
              ),
            }
          : v
      );
    } else {
      values = filter.values.map(v =>
        v.label === value.label
          ? { ...value, checked: e.target.checked, disabled: false }
          : v
      );
    }

    let filterFunctionParams = { filter, values };

    if (options?.arrayFilters?.length) {
      filterFunctionParams = {
        arrayFilters: [...options.arrayFilters, filter],
        name,
      };
    }

    if (options?.isFilterToReinitialize) {
      filterFunction = ({ arrayFilters, name }) => {
        let filters = {
          ...ADS_FILTERS,
          [name]: { ...filter, values: [...values] },
        };

        for (const filter of arrayFilters) {
          filters = {
            ...filters,
            [filter.name]: {
              ...filter,
              values: [...filter.values],
            },
          };
        }
        if (filter.name === ADS_FILTERS.dealer.name) {
          filter.values[0].checked = true;
          filters["model.brand"].values = filters["model.brand"].values.map(
            criteriaValue => ({ ...criteriaValue, checked: false })
          );
        }

        reinitializeAdsFiltersAndOrder({ ...filters });
      };
    }

    filterFunction(filterFunctionParams);
    await onChangeCallback();
  };

  return (
    <FormControlLabel
      control={
        <>
          <Checkbox
            name={name}
            classes={{ root: "custom-checkbox-root" }}
            onClick={handleClick}
            checked={value?.checked ?? false}
            disabled={value?.disabled ?? false}
          />
          {filter.type === "svg" && value?.svg && <Svg selection={value.svg} />}
        </>
      }
      label={label}
      className={className}
    />
  );
};

CheckboxFilter.propTypes = {
  //
};

export default connect(
  store => ({ ads: store.ads }),
  dispatch => ({
    changeFilters: bindActionCreators(changeFilters, dispatch),
    reinitializeAdsFiltersAndOrder: bindActionCreators(
      reinitializeAdsFiltersAndOrder,
      dispatch
    ),
  })
)(CheckboxFilter);
