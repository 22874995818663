import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { bindActionCreators } from "redux";
import { changeFilters } from "@/Store/actions/ads";
import { connect } from "react-redux";

const InputFilter = ({
  filterValue = {},
  filter = {},
  changeFilters = () => {},
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(filterValue.value);
  }, [filterValue.value]);

  useEffect(() => {
    return () => {
      setValue("");
    };
  }, []);

  const handleChange = e => {
    try {
      const inputValue = e.target.value;
      const updateFilter = (name = null, filter = null) => {
        let updatedFilter = null;

        if (name && filter) {
          const updatedFilterValues = filter?.values ?? [];
          const indexValueToUpdate = filter?.values?.findIndex(
            referenceValue => filterValue.name === referenceValue.name
          );

          if (indexValueToUpdate > -1) {
            updatedFilterValues[indexValueToUpdate] = {
              ...filterValue,
              value: inputValue,
            };
            updatedFilter = { ...filter, values: updatedFilterValues };
          }

          updatedFilter && changeFilters(filter.name, updatedFilter);
        }
      };

      setValue(inputValue);

      updateFilter(filterValue.name, filter);
    } catch (error) {
      if (!process.env.NEXT_PUBLIC_IS_PROD_ENV) console.error(error);

      console.error("Error in InputFilter : ", error);
    }
  };

  return (
    <>
      <TextField
        name={filterValue.name}
        label={filterValue.label}
        variant='outlined'
        onChange={handleChange}
        value={value}
        type={filterValue.type}
      />
    </>
  );
};

export default connect(null, dispatch => ({
  changeFilters: bindActionCreators(changeFilters, dispatch),
}))(InputFilter);
