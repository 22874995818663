import { useState } from "react";

import css from "./BlockCritere.module.css";
import Svg from "@/Shared/Svg";
import Critere from "../Critere";

import MySlider from "../MySlider";
import MultiCheckbox from "../MultiCheckbox";
import MultiSlider from "../MultiSlider";
import CheckboxFilter from "../CheckboxFilter";
import InputFilter from "../InputFilter";

// TODO a refactor will surelly be needed to split at a higher level the form inputs creation
const BlockCritere = ({
  classNames,
  classNamesGlobal,
  name,
  filter,
  cols,
  listCheckboxes,
  parent,
  fixHeight = false,
  fixHeight100 = false,
  avecMoteur,
  title = "with",
  ...otherProps
}) => {
  // console.log('--critere '+name+' : fixHeight ', filter.fixHeight, ' -- fixHeight100 ', filter.fixHeight100 )
  let criteres, listClassesTostring;
  const visibleStart = filter?.visible ? filter.visible : true;
  const [critereVisible, setCritereVisible] = useState(visibleStart);

  const toggleCritereVisibility = () => setCritereVisible(!critereVisible);

  if (classNamesGlobal)
    listClassesTostring = classNamesGlobal
      .split(" ")
      .map(classe => css[classe])
      .join(" ");

  // filter.values = sortBy(filter.values, ['label'])

  // TODO devrait être mis dans différentes fonctions
  switch (name) {
    case "checkboxesList":
      /* toop sur la props des choix à cocher NIVEAU 1 */
      const objNiv1 = listCheckboxes;
      const stockNiv2 = {};
      const arrNiv1 = [];

      const genererNiveaux = objNiv1 => {
        let count = 0;

        for (const keyNiv1 in objNiv1) {
          if (objNiv1.hasOwnProperty(keyNiv1)) {
            genererPremierNiveau(objNiv1, keyNiv1, count);

            if (objNiv1[keyNiv1]?.finitions) {
              genererSecondNiveau(objNiv1[keyNiv1].finitions);
              arrNiv1[arrNiv1.length] = (
                <>{stockNiv2[objNiv1[keyNiv1].finitions]}</>
              );
            }
          }
          count++;
        }
      };

      const genererPremierNiveau = (objNiv1, keyNiv1, index) => {
        const Niv1ToPush = objNiv1[keyNiv1]?.finitions ? (
          <Critere
            type='icoHasNiv2'
            label={objNiv1[keyNiv1].label}
            key={index}
          />
        ) : (
          <Critere type='checkbox' label={objNiv1[keyNiv1].label} key={index} />
        );
        arrNiv1.push(Niv1ToPush);
      };

      const genererSecondNiveau = objNiv2 => {
        const arrNiv2 = [];

        objNiv2?.forEach((keyNiv2, index) => {
          if (objNiv2.hasOwnProperty(keyNiv2)) {
            //console.log(keyNiv2 + " -> " + objNiv2[keyNiv2].label)
            arrNiv2.push(
              <Critere
                type='checkbox'
                label={objNiv2[keyNiv2].label}
                key={index}
              />
            );
          }

          stockNiv2[objNiv2] = (
            <div className={css["u-sousniveau"]}>
              {arrNiv2?.map(critereNiv2 => critereNiv2)}
            </div>
          );
        });
      };

      genererNiveaux(objNiv1);

      criteres = (
        <div
          className={`${css["c-search__form__listing-options"]} 
          ${
            fixHeight || filter?.fixHeight
              ? css["c-search__form__listing-options--fix-height"]
              : ""
          } 
          ${
            fixHeight100 || filter?.fixHeight100
              ? css["c-search__form__listing-options--fix-height-100"]
              : ""
          }`}
        >
          {arrNiv1.map(critere => critere)}
        </div>
      );

      if (avecMoteur) {
        criteres = (
          <div
            className={`${css["c-search__concessionnaire__englob"]} ${
              css["critere-marques"]
            } ${critereVisible ? "u-visible-critere" : "u-invisible-critere"}`}
          >
            <Svg
              selection='ico-loupe'
              classNames='ico-loupe-reversed ico-on-input-txt'
            />
            <Critere type='textfield' tPlaceholder='Ex: Rapido' isSvg />
            {criteres}
          </div>
        );
      }

      break;

    case "localisation":
      criteres = (
        <div className={css["c-search__form__listing-options"]}>
          {/* BLOC CRITERE TEXTFIELD SUR TOUTE LA LARGEUR */}
          <div className={css["c-search__form__textfield--wide"]}>
            <Critere type='textfield' tPlaceholder='Code Postal' />
          </div>
          {/* BLOC CRITERE TEXTFIELD EN INLINE DANS UNE PHRASE */}
          <div className={css["c-search__form__textfield-inline"]}>
            <span>élargir à</span>
            <div className={css["c-search__form__textfield-inline__input"]}>
              <Critere type='textfield' tPlaceholder='100' />
            </div>
            <span>km</span>
          </div>
        </div>
      );

      break;
  }

  // TODO devrait mis dans une fonction
  let classes = "";
  switch (filter?.type) {
    case "button":
      classes = "c-search__groupe__space--reduce";
      break;
    case "multi-slider":
      classes = "";
      break;
    default:
      classes = "c-search__groupe__space";
      break;
  }

  // TODO devrait mis dans une fonction
  let childClasses = "";
  switch (filter?.type) {
    case "button":
      childClasses = "c-search__form__cell-select";
      break;
    case "svg":
      childClasses = "c-search__form__img-select";
      break;
    case "slider":
      childClasses = "c-search__form__slider";
      break;
    case "multi-slider":
      break;
    case "checkbox":
    case "multi-text":
    default:
      childClasses = "c-search__form__listing-options";
      break;
  }

  return (
    <>
      {filter ? (
        <div className={`${css["c-search__critere"]} ${css[classes]}`}>
          {filter.label && title === "with" ? (
            <h2
              className={`${
                css["c-search__critere__titre"]
              } f-primary f-primary--700 
        ${
          filter.type === "multi-slider"
            ? css["c-search__critere__titre-multicriteres"]
            : ""
        }
      `}
              onClick={toggleCritereVisibility}
            >
              {filter.label}
              <Svg selection='ico-toggle' />
            </h2>
          ) : (
            ""
          )}

          {/* TODO apply disabled style class when filter?.disabled === true */}
          <div
            className={`${css[childClasses]} 
        ${cols === 3 && filter.type === "svg" ? css["c-critere-x3"] : ""} 
        ${filter.type === "multi-slider" ? "u-multicriteres" : ""} 
        ${filter.type === "multi-slider" ? css["u-multicriteres"] : ""} 
        ${critereVisible ? "u-visible-critere" : "u-invisible-critere"} 
        ${
          fixHeight || filter?.fixHeight
            ? css["c-search__form__listing-options--fix-height"]
            : ""
        } 
        ${
          fixHeight100 || filter?.fixHeight100
            ? css["c-search__form__listing-options--fix-height-100"]
            : ""
        }
      `}
          >
            {/* Slider */}
            {filter.type === "slider" ? (
              <MySlider name={name} filter={filter} parent={parent} />
            ) : (
              ""
            )}

            {/*// Checkbox simple /i/ button -> neuf | occasion /i/ svg -> carrosseries | couchages*/}
            {["button", "svg", "checkbox"].includes(filter.type) &&
              filter.values?.map((value, index) => (
                <CheckboxFilter
                  name={name}
                  filter={filter}
                  key={index}
                  value={value}
                />
              ))}

            {/* Plusieurs sliders */}
            {filter.type === "multi-slider" && filter.values ? (
              <MultiSlider name={name} filter={filter} />
            ) : (
              ""
            )}

            {/* Plusieurs checkbox multi niveaux (cas typique des marques/modèles/finition)*/}
            {filter.type === "multi-checkbox" && filter.values ? (
              <div
                className={`${css["c-search__form__listing-options"]} ${
                  fixHeight || filter?.fixHeight
                    ? css["c-search__form__listing-options--fix-height"]
                    : ""
                }
         ${
           fixHeight100 || filter?.fixHeight100
             ? css["c-search__form__listing-options--fix-height-100"]
             : ""
         }`}
              >
                {filter.values.map(level1 => (
                  <MultiCheckbox
                    name={name}
                    filter={filter}
                    level1={level1}
                    key={level1.name}
                  />
                ))}
              </div>
            ) : (
              ""
            )}

            {/* ex: 'localisation' -> périmètre de recherche multi critere CP - distance */}
            {filter.type === "multi-text" && filter.values
              ? filter.values?.map((value, i) => {
                  return (
                    <div
                      key={i}
                      className={
                        css[`c-search__form__textfield-${value.layout}`]
                      }
                    >
                      {value.before ? <span>{value.before}</span> : ""}
                      <InputFilter filterValue={value} filter={filter} />
                      {value.after ? <span>{value.after}</span> : ""}
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      ) : (
        <div
          className={`
            ${otherProps.type !== "titreOnly" ? css["c-search__critere"] : ""}
            ${listClassesTostring ? listClassesTostring : ""}
            ${classNames}
          `}
        >
          {otherProps.label ? (
            <h2
              className={`${css["c-search__critere__titre"]} f-primary f-primary--700`}
              onClick={toggleCritereVisibility}
            >
              {otherProps.label}
              <Svg selection='ico-toggle' />
            </h2>
          ) : (
            ""
          )}
          {otherProps.type !== "titreOnly" ? criteres : ""}
        </div>
      )}
    </>
  );
};

BlockCritere.propTypes = {};

export default BlockCritere;
