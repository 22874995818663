import css from "@/Public/Articles.module.css";
import Proptypes from "prop-types";
import React from "react";

export const SeoParagraph = ({ children }) => {
  // eslint rule no-irregular-whitespace
  // https://eslint.org/docs/latest/rules/no-irregular-whitespace#:~:text=This%20rule%20is%20aimed%20at,a%20debugging%20issue%20to%20spot.
  return (
    <section
      className={`${css["c-SeoParagraph"]} ${css["corps"]} ${css["l-fix-w"]}`}
    >
      <div className={css["englob"]}>{children}</div>
    </section>
  );
};

SeoParagraph.propTypes = {
  children: Proptypes.element,
};

export const HomeParagraph = (
  <>
    <h2>
      Plus de 1000 annonces de camping-cars, fourgons aménagés, caravanes et
      vans disponibles à la vente
    </h2>

    <p>
      Vous êtes à la recherche d’un camping-car, d’un fourgon aménagé, d’un van
      ou d’une caravane afin de profiter d’un maximum de liberté lors de vos
      prochaines vacances ? Alors découvrez dès à présent, sur WeekandGO, une
      vaste sélection de véhicules de loisirs, neufs ou d&apos;occasion,
      disponibles à la vente.
    </p>

    <h3>Des véhicules pour tous les goûts</h3>

    <p>
      Que vous préfériez voyager en camping-car, avec une caravane, en fourgon
      aménagé, ou même, avec un van, vous trouverez sur WeekandGO des annonces
      de véhicules à vendre qui correspondront à vos attentes. De plus, vous
      pouvez aussi rechercher un véhicule en fonction des options dont vous avez
      besoin&nbsp;lors de vos voyages et du budget d’achat dont vous
      disposez&nbsp;: type de couchage principal pour un camping-car (lit à la
      française, lits superposés, lit central, etc.),&nbsp;prix du véhicule,
      type de boite de vitesse, chauffage, etc.
    </p>

    <h3>Des marques prestigieuses</h3>

    <p>
      Sur WeekandGO vous trouverez de nombreuses marques connues et reconnues
      pour la qualité de leurs véhicules&nbsp;: Challenger, Burstner, Renault,
      Rapido, Chausson, Eriba, Autostar, Volkswagen, et bien d&apos;autres. Vous
      pouvez d’ailleurs filtrer votre recherche afin de ne faire apparaître
      uniquement les véhicules des marques qui vous intéressent.
    </p>

    <h3>Conseils et guides pratiques</h3>

    <p>
      Vous avez besoin de conseils afin de bien choisir votre futur camping-car
      (ou un autre véhicule de loisir)&nbsp;? Vous voulez en savoir plus sur la
      meilleure manière de passer vos vacances avec un camping-car&nbsp;? Dans
      ce cas, nous vous invitons à consulter sans attendre notre blog&nbsp;! Ce
      dernier regorge d&apos;articles qui devraient vous aider à y voir plus
      clair : guide sur l&apos;achat d&apos;un camping-car d&apos;occasion, des
      astuces pour bien préparer votre voyage en camping-car, des conseils liés
      à la vidange des eaux usées de votre camping-car, des suggestions pour
      voyager sereinement avec vos enfants et bien d’autres conseils.
    </p>
  </>
);

export const OffersParagraph = (
  <>
    <h2>
      Découvrez de nombreuses annonces de camping-cars et autres véhicules de
      loisirs à vendre sur WeekandGO
    </h2>

    <p>
      Découvrez, au sein de cette page, de nombreuses annonces de camping-cars,
      fourgons aménagés, vans et caravanes disponibles à la vente, les véhicules
      en question sont proposés à la vente par des particuliers ou par des
      professionnels.
    </p>

    <h3>De nombreux types de véhicules disponibles</h3>

    <p>
      Qu&apos;il s&apos;agisse d&apos;un camping-car spacieux pour des aventures
      familiales, d&apos;un fourgon aménagé compact idéal pour partir à
      l&apos;aventure en toute liberté, ou encore d&apos;un van conçu pour les
      escapades spontanées, vous trouverez ici une importante sélection de
      véhicule&nbsp;! Il y en a pour tous les goûts&nbsp;: vous devriez trouver
      LE véhicule qui correspondra à vos besoins et envies.
    </p>

    <h3>Des recherches facilitées</h3>

    <p>
      Utilisez nos filtres de recherche pour affiner votre sélection selon des
      critères spécifiques&nbsp;: type de carrosserie, capacité de couchage,
      marques préférées et bien plus encore. Chez WeekandGO, nous mettons tout
      en œuvre pour simplifier votre recherche et vous aider à trouver le
      véhicule de loisirs parfaitement adapté à vos besoins.
    </p>
  </>
);
