import React from "react";
import css from "./MultiSlider.module.css";
import MySlider from "../MySlider";

const MultiSlider = ({ name, filter }) => {
  return (
    <>
      {filter?.values &&
        Object.values(filter.values).map(subfilter => (
          <div
            key={subfilter.name}
            className={`${css["c-search__critere"]} ${css["c-search__groupe__space"]}`}
          >
            {subfilter.label && (
              <h3
                className={`${css["c-search__critere__titre"]} f-primary f-primary--700`}
              >
                {subfilter.label}
              </h3>
            )}
            <div className={`${css["c-search__form__slider"]}`}>
              <MySlider name={name} filter={subfilter} parent={filter} />
            </div>
          </div>
        ))}
    </>
  );
};

MultiSlider.propTypes = {
  //
};

export default MultiSlider;
