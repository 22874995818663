import React from "react";

import css from "./MultiCheckbox.module.css";
import MyCheckbox from "../CheckboxFilter";
import Svg from "../../Svg";

{
  /* TODO : gérer le CSS du niveau 3 */
}

const MultiCheckbox = ({ name, filter, level1 }) => {
  // console.log('name : ', name)
  // console.log('filter : ', filter)
  // console.log('level1 : ', level1)

  return level1?.values?.length > 0 ? (
    <>
      {/* <small>{level1?.label}</small> */}
      {level1?.values?.map(level2 =>
        level2?.values ? (
          <>
            <a
              href='#'
              key={level2.name}
              className={`${css["c-critere-toggleNiv2-link"]}`}
            >
              <Svg selection='ico-niv2-moins' />
              <Svg selection='ico-niv2-plus' />
              {level2.label && level2.label}
            </a>
            {level2.values?.map(level3 =>
              level3 ? (
                <MyCheckbox
                  name={name}
                  key={level3.name}
                  parent={level1}
                  value={level3}
                  filter={filter}
                />
              ) : (
                ""
              )
            )}
          </>
        ) : level2 ? (
          <MyCheckbox
            name={name}
            key={level2.name}
            parent={level1}
            value={level2}
            filter={filter}
          />
        ) : (
          ""
        )
      )}
    </>
  ) : (
    ""
  );
};

MultiCheckbox.propTypes = {
  //
};

export default MultiCheckbox;
