import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import { connect } from "react-redux";

import { changeFilters } from "@/Store/actions/ads";
import { bindActionCreators } from "redux";
import { ADS_FILTERS } from "@/Constants/filters";

/**
 * @description slider utilisé entre autres pour les filtres. Utilise le slider
 * de MUI (https://mui.com/material-ui/react-slider/)
 * @param changeFilters
 * @param name
 * @param filter
 * @param parent
 * @returns {JSX.Element}
 * @constructor
 */
const MySlider = ({ changeFilters, name, filter, parent }) => {
  const [value, setValue] = useState([]);
  const marks = [
    { value: filter.min, label: filter.min },
    { value: filter.max, label: filter.max },
  ];

  // Mise à jour du slider après supression d'un tag
  useEffect(() => {
    if (filter) {
      const highValue =
        filter.values?.[1] ??
        filter.max ??
        ADS_FILTERS?.[filter.name]?.max ??
        ADS_FILTERS.size?.[filter.name]?.max;

      const lowValue =
        filter.values?.[0] ??
        filter.min ??
        ADS_FILTERS?.[filter.name]?.min ??
        ADS_FILTERS.size?.[filter.name]?.min;

      setValue([lowValue, highValue]);
    }
  }, [filter]);

  // Se déclenche uniquement lorsque la jauge du slider est relachée
  // TODO : Ajouter un debounce time pour éviter de relancer la requête trop vite
  const handleCommit = e => {
    e.preventDefault();
    const values = [
      value[0] !== filter.min ? value[0] : null,
      value[1] !== filter.max ? value[1] : null,
    ];

    let criteria;

    if (parent) {
      criteria = {
        ...parent,
        values: parent.values.map(filterInParent =>
          filterInParent.name === filter.name
            ? { ...filter, values: [...values] }
            : filterInParent
        ),
      };
    } else {
      criteria = { ...filter, values: [...values] };
    }

    if (criteria) {
      changeFilters(name, criteria);
    }
  };

  const handleSlide = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Slider
      value={value}
      onChange={handleSlide}
      onChangeCommitted={handleCommit}
      valueLabelDisplay={"auto"}
      step={filter.step}
      min={filter.min}
      max={filter.max}
      aria-labelledby={filter.label}
      marks={marks}
      name={filter.name}
    />
  );
};

MySlider.propTypes = {
  //
};

export default connect(null, dispatch => ({
  changeFilters: bindActionCreators(changeFilters, dispatch),
}))(MySlider);
